// export const ADMIN = 'https://api.survey.dev.cancopetroleum.ca/api/admin';
export const ADMIN = process.env.REACT_APP_BASE_URL;

export const LOGIN = `${ADMIN}/login/`;
export const USERS = `${ADMIN}/user/`;
export const STORE = `${ADMIN}/store/`;
export const MODULE = `${ADMIN}/module/`;
export const THIRDPARTY_ORG = `${ADMIN}/thirdparty/org/`;
export const THIRDPARTY_SITE = `${ADMIN}/thirdparty/site/`;
export const FUEL_TYPES = `${ADMIN}/fuel_type/`;
export const FTP_CONFIGURATIONS = `${ADMIN}/ftp_configuration/`;
export const SURVEY = `${ADMIN}/survey/`;

export const CITY = `${ADMIN}/city/`;
export const PROVINCE = `${ADMIN}/province/`;
export const PRICE_PUSH = `${ADMIN}/price_request/`;
