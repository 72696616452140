import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import { PATH_AFTER_LOGIN } from '../config';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'master-configuration',
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'canco-store', element: <CancoStores /> },
            { path: 'third-party-sites', element: <ThirdPartySites /> },
            { path: 'third-party-organisations', element: <ThirdPartyOrganisation /> },
            { path: 'fuel-types', element: <FuelTypes /> },
            { path: 'ftp-configuration', element: <FTPConfigurationCreate /> },
            {
              path: 'canco-store',
              children: [
                { path: 'store/new', element: <CancoStoreCreate /> },
                { path: 'store/:name/edit', element: <CancoStoreCreate /> },
              ],
            },
            {
              path: 'third-party-sites',
              children: [
                { path: 'new', element: <ThirdPartySitesCreate /> },
                { path: ':name/edit', element: <ThirdPartySitesCreate /> },
              ],
            },
            {
              path: 'third-party-organisations',
              children: [
                { path: 'new', element: <ThirdPartyOrganisationCreate /> },
                { path: ':name/edit', element: <ThirdPartyOrganisationCreate /> },
              ],
            },
            {
              path: 'fuel-types',
              children: [
                { path: 'new', element: <FuelTypeCreate /> },
                { path: ':name/edit', element: <FuelTypeCreate /> },
              ],
            },
            {
              path: 'ftp-configuration',
              children: [
                { path: 'new', element: <FTPConfigurationCreate /> },
                { path: ':name/edit', element: <FTPConfigurationCreate /> },
              ],
            },
          ],
        },
        {
          path: 'surveys',
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            {
              path: 'assigned-sites',
              children: [
                { element: <AssignedSites />, index: true },
                { path: ':id/edit', element: <AssignedSitesCreateEdit /> },
                { path: 'new', element: <AssignedSitesCreateEdit /> },
              ],
            },
            {
              path: 'surveys-list',
              children: [
                { element: <SurveyList />, index: true },
                { path: ':id', element: <SurveyDetail /> },
                { path: 'assigned-sites/:id', element: <SurveyAssignedSites /> },
              ],
            },
          ],
        },
        {
          path: 'price-push',
          children: [
            { element: <PricePushList />, index: true },
            { path: ':id/edit', element: <PricePushCreateEdit /> },
            { path: ':id', element: <PricePushDetail /> },
            { path: 'new', element: <PricePushCreateEdit /> },
          ],
        },

        {
          path: 'user-management',
          children: [
            { element: <UserManagement />, index: true },
            { path: ':id/edit', element: <UserCreateEdit /> },
            { path: 'new', element: <UserCreateEdit /> },
          ],
        },
        {
          path: 'welcome',
          children: [{ element: <Welcome />, index: true }],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const Welcome = Loadable(lazy(() => import('../pages/dashoard/welcome')));
const CancoStores = Loadable(lazy(() => import('../pages/dashoard/MasterConfiguration/CancoStores')));
const CancoStoreCreate = Loadable(lazy(() => import('../pages/dashoard/MasterConfiguration/CancoStoreCreate')));

const ThirdPartySites = Loadable(lazy(() => import('../pages/dashoard/MasterConfiguration/ThirdPartySites')));
const ThirdPartySitesCreate = Loadable(
  lazy(() => import('../pages/dashoard/MasterConfiguration/ThirdPartySitesCreate'))
);

const ThirdPartyOrganisation = Loadable(
  lazy(() => import('../pages/dashoard/MasterConfiguration/ThirdPartyOrganisation'))
);
const ThirdPartyOrganisationCreate = Loadable(
  lazy(() => import('../pages/dashoard/MasterConfiguration/ThirdPartyOrganisationCreate'))
);

const FuelTypes = Loadable(lazy(() => import('../pages/dashoard/MasterConfiguration/FuelTypes')));
const FuelTypeCreate = Loadable(lazy(() => import('../pages/dashoard/MasterConfiguration/FuelTypeCreate')));

const FTPConfiguration = Loadable(lazy(() => import('../pages/dashoard/MasterConfiguration/FTPConfiguration')));
const FTPConfigurationCreate = Loadable(
  lazy(() => import('../pages/dashoard/MasterConfiguration/FTPConfigurationCreate'))
);

const UserManagement = Loadable(lazy(() => import('../pages/dashoard/UserManagement')));
const UserCreateEdit = Loadable(lazy(() => import('../pages/dashoard/UserManagement/UserCreateEdit')));

const AssignedSites = Loadable(lazy(() => import('../pages/dashoard/Survey/AssignedSites')));
const AssignedSitesCreateEdit = Loadable(
  lazy(() => import('../pages/dashoard/Survey/AssignedSites/AssignedSitesCreateEdit'))
);
const SurveyList = Loadable(lazy(() => import('../pages/dashoard/Survey/Survey')));
const SurveyDetail = Loadable(lazy(() => import('../pages/dashoard/Survey/Survey/SurveyDetail')));
const SurveyAssignedSites = Loadable(lazy(() => import('../pages/dashoard/Survey/Survey/SurveyAssignedSites')));
const PricePushList = Loadable(lazy(() => import('../pages/dashoard/PricePusing/PricePushList')));
const PricePushCreateEdit = Loadable(lazy(() => import('../pages/dashoard/PricePusing/PricePushCreateEdit')));
const PricePushDetail = Loadable(lazy(() => import('../pages/dashoard/PricePusing/PricePushDetail')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
