import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Image from './Image';
import { PATH_AFTER_LOGIN } from '../config';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, isLogin }) {
  const theme = useTheme();
  const { themeMode } = useSettings();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  const logo = <Image
    alt="Canco"
    sx={{
      width: 'calc(100% - 8em)',
      height: 'calc(100% - 8em)',
    }}
    src={'/assets/canco_logo.png'}
  />;

  if (disabledLink) {
    return <Image
      alt="Canco"
      sx={{
        width: '40px',
        height: '70px',
      }}
      src={'/assets/canco_logo.png'}
    />;
  }
  if (isLogin) {
    return <Image
      alt="Canco"
      sx={{
        width: 'calc(30% - 18em)',
        height: 'calc(30% - 18em)',
      }}
      src={'/assets/canco_logo.png'}
    />;
  }

  return <RouterLink to={PATH_AFTER_LOGIN}>{logo}</RouterLink>;
}