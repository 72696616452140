// components
import SvgIconStyle from '../../../components/SvgIconStyle';

import { PATH_DASHBOARD } from '../../../routes/paths';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'Master Configuration',
        path: PATH_DASHBOARD.masterConfiguration.cancoStore,
        icon: ICONS.dashboard,
        children: [
          { title: 'Canco Stores', path: PATH_DASHBOARD.cancoStore.root },
          { title: 'Third Party Sites', path: PATH_DASHBOARD.thirdPartySites.root },
          { title: 'Third Party Organisations', path: PATH_DASHBOARD.thirdPartyOrganisations.root },
          { title: 'Fuel Types', path: PATH_DASHBOARD.fuelTypes.root },
          { title: 'FTP Configuration', path: PATH_DASHBOARD.ftpConfiguration.root },
        ],
      },
      {
        title: 'Surveys',
        path: PATH_DASHBOARD.assignedSites.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Assigned Sites', path: PATH_DASHBOARD.assignedSites.root },
          { title: 'Surveys', path: PATH_DASHBOARD.surveys.root },
        ],
      },
      { title: 'Price Push', path: PATH_DASHBOARD.pricePush.root, icon: ICONS.ecommerce },
      { title: 'User Management', path: PATH_DASHBOARD.userManagement.root, icon: ICONS.user },
    ],
  },
];

export default navConfig;
