import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

export const authApi = axios.create({
  baseURL: HOST_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

authApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const adminApi = axios.create({
  baseURL: HOST_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

adminApi.interceptors.request.use(
  (options) => {
    options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return options;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

adminApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

// adminApi.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     // Check if the error is a permission error
//     if (error.response && error.response.status === 403) {
//       // Redirect the user to another page
//       history.push('/permission-denied');
//     }
//     return Promise.reject(error);
//   }
// );
