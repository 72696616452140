import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.000');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fPricePush(e) {
  let { value } = e.target;

  // Remove non-numeric characters except for the first '-'
  value = value.replace(/[^\d.-]/g, '');

  // Ensure only one '-' at the beginning
  value = value.replace(/^-+/g, '-');

  const checkNegative = value.slice(0, 2);

  if (checkNegative === '-1') {
    return checkNegative;
  }

  // Add decimal point if it's a whole number
  if (/^(-)?\d+$/.test(value)) {
    value = `${value}.`;
  }

  // Ensure only one digit before the decimal point
  const intPart = value.split('.');
  if (intPart[0].length > 1) {
    // value = intPart[0][0] + '.' + (intPart[1] || '');
    value = `${intPart[0][0]}.${intPart[1] || ''}`;
  }

  // Ensure only one decimal point is present
  const parts = value.split('.');
  if (parts.length > 2) {
    // value = parts[0] + '.' + parts.slice(1).join('');
    value = `${parts[0]}.${parts.slice(1).join('')}`;
  }

  // Limit to 3 decimal places
  const [integerPart, decimalPart] = value.split('.');
  if (decimalPart) {
    value = `${integerPart}.${decimalPart.slice(0, 3)}`;
  }

  return value;
}
