// import { capitalCase } from 'change-case';
// import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { styled } from '@mui/material/styles';
// import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Grid } from '@mui/material';
import { Box, Typography, Grid, Container } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
// import useAuth from '../../hooks/useAuth';
// import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
// styles
import styles from '../../styles/pages/login.module.scss';
// Importing Images
import siteLogo from '../../assets/siteLogo.png';
import cancoPump from '../../assets/cancoPetrolPump.png';
// ----------------------------------------------------------------------

// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

// const HeaderStyle = styled('header')(({ theme }) => ({
//   top: 0,
//   zIndex: 9,
//   lineHeight: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   position: 'absolute',
//   padding: theme.spacing(3),
//   justifyContent: 'space-between',
//   [theme.breakpoints.up('md')]: {
//     alignItems: 'flex-start',
//     padding: theme.spacing(7, 5, 0, 7),
//   },
// }));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2),
// }));

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   minHeight: '100vh',
//   display: 'flex',
//   justifyContent: 'center',
//   flexDirection: 'column',
//   padding: theme.spacing(12, 0),
// }));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();

  // const smUp = useResponsive('up', 'sm');

  // const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <section className={styles.loginHeader}>
        <Box>
          <Image
            visibleByDefault
            disabledEffect
            src={siteLogo}
            alt="login"
            sx={{ maxWidth: '250px', margin: '0 auto' }}
          />
        </Box>
      </section>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={styles.loginContainer}>
          <Grid item lg={4} className={styles.loginFormCol}>
            <Typography variant="p" sx={{mb: 2}} fontSize={"12px"} fontWeight={"600"} color={"#ff4d00"}>WELCOME TO</Typography>
            <Typography variant="h3" sx={{ mb: 5 }}>
              Fuel Survey Admin
            </Typography>
            <LoginForm />
          </Grid>
          <Grid item lg={8}>
            <Image visibleByDefault disabledEffect src={cancoPump} alt="Canco Petrol Pump" />
          </Grid>
        </Grid>
      </Box>
      <section className={styles.loginFooter}>
        <Container>
          <p>2024 © Canco Petroleum Ltd.</p>
        </Container>
      </section>
      {/* <RootStyle>
        <HeaderStyle>
          <Logo isLogin />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image visibleByDefault disabledEffect src="/assets/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Canco Store
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
            </Stack>

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle> */}
    </Page>
  );
}
